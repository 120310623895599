import React, { useContext} from 'react';
import { Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { Fade } from "react-awesome-reveal";

library.add(fab);


const Skills = () => {
    const { skills, experiences } = useContext(PortfolioContext);
    const { skillsIcons } = skills;
    const { experience } = experiences;
  
    return (
      <>
       <Title title="Skills & Experiences"/>
       <Container className="app__skills-container">
       <Fade triggerOnce direction='down' duration={1000} delay={1200}>
       <div className="app__skills-list">
          {skillsIcons &&
            skillsIcons.map(skill => {
              const { id, icon, name } = skill;
              return (
                <div key={id} className='app__skills-item'>
                  <FontAwesomeIcon className='icon' icon={['fab', `${icon}`]} size='6x' fixedWidth  />
                  <p className="p-text">{name}</p>
                </div>
                );
            })}
        </div>
        </Fade>
        <Fade triggerOnce direction='right' duration={1000} delay={1400}>
        <div className="app__skills-exp">
          {experience &&
            experience.map((experience) => (
            <div
              className="app__skills-exp-item"
              key={experience.id}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <div className="app__skills-exp-works">
                {experience.jobs.map((job) => (
                  <>
                    
                    <OverlayTrigger key={experience.id} placement='top'
                    overlay={
                      <Tooltip 
                      id={job.name}
                      className="skills-tooltip"
                      >
                        {job.desc}
                      </Tooltip >
                    }
                    >
                      <div
                        className="app__skills-exp-work"
                        data-for={job.name}
                        key={job.name}
                      >
                        <h4 className="bold-text">{job.name}</h4>
                        <p className="p-text">{job.company}</p>
                      </div>      
                    </OverlayTrigger>
                  </>
                ))}
              </div>
            </div>
            ))}
        </div>
        </Fade>
       </Container>
      </>
    );
  };

export default Skills;